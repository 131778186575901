//	================================================================================================
//	File Name: components-variables.scss
//	Description: Custom theme specific variables and other content variations
//	----------------------------------------------------------------------------------------------
//	Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
//	Author: PIXINVENT
//	Author URL: http://www.themeforest.net/user/pixinvent
//	================================================================================================

// ================================================================================================

// WARNING: THIS FILE WILL GET OVERWRITTEN WITH EACH MODERN TEMPLATE RELEASE.

// TIP: YOU CAN CUSTOMIZE THEME COMPONENTS VARIABLE AS PER YOUR REQUIREMENTS, IT WILL AFFECT BOTH
//      LAYOUTS MATERIAL & DEFAULT. ALWAYS KEEP BACKUP IF YOU CHANGE THIS FILE.

// ================================================================================================

$base-font-size: 14px;
$body-direction: ltr; // Default ltr, change it to rtl for Right To Left support.

//	------------------------------
//	  Colors
//	------------------------------
$border-color: #E4E5EC;
$transparent: transparent;
$hover-color: #F2F4F4;
$swiper-bg: #f2f4f4;
$content-bg: #F5F7FA;
$avatar-bg: #c3c3c3;

//	------------------------------
//	  Buttons
//	------------------------------
$btn-border-radius-square: 0;
$btn-border-radius-round: 2rem;

//	------------------------------
//	  Navbar
//	------------------------------
$navbar-height:5rem;
$navbar-light-bg: $white;
$navbar-dark-bg: #2c343b;
$navbar-header-width : 260px;
$navbar-law-image-bg: #25221F;

//	------------------------------
//	  Main Menu
//	------------------------------


//main menu light
$menu-light-bg: $white;
$menu-light-color: $gray-600;

//main menu dark
$menu-dark-bg: #2c343b;
$menu-dark-color: #dcdcdc;

$menu-padding : 12px 30px 12px 18px;
$menu-second-level-padding : 12px 18px 12px 54px;
$menu-third-level-padding : 12px 18px 12px 64px;
$menu-forth-level-padding : 12px 18px 12px 74px;

// vertical menu
$menu-expanded-width: 260px;
$menu-collapsed-width: 60px;

// vertical overlay menu
$overlay-menu-width: 280px;

// vertical compact menu
$compact-menu-width: 120px;
$compact-boxed-menu-width: 120px;

// vertical mm menu
$mm-expanded-width: 260px;
$mm-collapsed-width: 260px;

// vertical menu
$content-menu-expanded-width: 260px;
$content-menu-collapsed-width: 70px;

// menu search
$menu-search-height: 70px;

//Main menu footer
$menu-footer-height: 15px;
$menu-footer-color: $white;
$menu-footer-color-bg: #2C303B;
$menu-footer-link-bg: rgb(33, 41, 46);
$menu-footer-link-hover-bg: rgb(30, 36, 39);

//Footer color
$footer-color-light-bg: $white;
$footer-color-dark-bg : #2C303B;

//	------------------------------
//	  Sideber
//	-------------------------------
$sidebar-width: 300px;
$chat-sidebar-width: 300px;
//	-------------------------------
//	  Avatar
//	-------------------------------

$avatar-size: 36px;
$avatar-status-size: 10px;

$avatar-size-lg: 54px;
$avatar-status-size-lg: 20px;

$avatar-size-md: 40px;
$avatar-status-size-md: 10px;

$avatar-size-sm: 32px;
$avatar-status-size-sm: 8px;

$avatar-size-xs: 24px;
$avatar-status-size-xs: 7px;

$avatar-size-50: 50px;
$avatar-status-size-50: 10px;

$avatar-size-100: 100px;
$avatar-status-size-100: 20px;

$avatar-online-color: $success;
$avatar-off-color: $gray-100;
$avatar-busy-color: $danger;
$avatar-away-color: $warning;

//	-------------------------------
//	Progress
//	-------------------------------
$progress-size-xl: 2rem;
$progress-size-lg: 1.5rem;
$progress-size-md: 1rem;
$progress-size-sm: 0.5rem;
$progress-size-xs: 0.25rem;

//	-------------------------------
//	Form
//	-------------------------------

// $input-height, $input-height-lg, $input-height-sm are in variables
$font-size-xs: .75rem;
$font-size-xl: ($font-size-base + 0.50);
$line-height-xl: 1.7;
$line-height-xs: 1.5;
$input-padding-y-xl: 0.5rem !default;
$input-padding-x-xl: 0.5rem !default;

$input-padding-y-xs: 0.2rem !default;
$input-padding-x-xs: 0.275rem !default;

$border-radius-xl: .35rem !default;
$border-radius-xs: .12rem !default;
$input-border-radius-xl: $border-radius-xl;
$input-border-radius-xs: $border-radius-xs;

$input-height-xl: (($font-size-xl * $line-height-xl) + ($input-padding-y-xl * 2)) !default;
$input-height-xs: (($font-size-xs * $line-height-xs) + ($input-padding-y-xs * 2)) !default;
$input-height-sm: (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2)) !default;


//	-------------------------------
//	Table
//	-------------------------------
$table_border_color: #e3ebf3;

//  Kanban App
$kanban-board-bg: #e7edf3;
$kanban-sidebar-label: #bac0c7;

// Todo Application
$todo-filters-color: #bac0c7;
$todo-star-color: #c7cfd6;
$todo-avatar-bg: #C3C3C3;
$todo-border-color: #E4E7ED;

// App Chat
$app-chat-message-bg: #fafbfb;
$chat-badge-bg: #f3f8fd;

// Email Application
$selected-email-bg: #e7edf3;
$detail-mail-bg: #fafbfb;
$favorite-star-color: #c7cfd6;
$email-menu-expanded-width: 300px;
