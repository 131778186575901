/* You can add global styles to this file, and also import other style files */
/*@import "~bootstrap/dist/css/bootstrap.css";

body{
    background-color: #f7f7f7 !important;
}

.margin-top-70{
    margin-top: 70px;
}*/
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "~ng-pick-datetime-ex/assets/style/picker.min.css";    
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");

:host ::ng-deep .email-fixed-header {
  min-height: calc(100% - 207px)!important;
  margin-top: 0!important;
  overflow-y: hidden !important;
}

.li-red {
  color: #FD1812
}

.li-gray
{
  color: #25221F;
}

.li-black {
  color: #25221F;
}

.li-title{
  color: #0F0A34;
  font-size: 20px;
  font-family: Montserrat;
}

.li-menu {
  color: #666666 !important;
  font-size: 13px !important;
  font-family: Montserrat !important;
}
.datatable-header-cell-label {
  color: #666666 !important;
  font-size: 13px !important;
  font-family: Montserrat !important;  
}

h2 {
  color: #0F0A34 !important;
  font-size: 20px !important;
  font-family: Montserrat !important;
}

h5 {
  font-family: Montserrat !important;
}

.li-bg-red {
  background-color: #FD1812 !important;
  color: white !important;
}

.readonly {
  background-color: rgb(237, 239, 241) !important;
}

.li-bg-special {
  background-color: #D15883;
}

.li-primary-button {
  background-color: #1F569F !important;
  color: white !important;
}

.li-red-button {
  background-color: #F15742 !important;
  color: white !important;
}

.li-black-button {
  background-color: #25221F !important;
  color: white !important;
}

.circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  color: white;
}

.new {
  background: #8ea9d0;
}

a {
  color: #0F0A34;
}

a:hover {
  color: #FD1812;
}

.active-link {
  color: #FD1812 !important;
}

.quantityInput
{
  width: 50px;
}

.top {
  z-index: 10000;
}

.header-menu {
  padding-left: 30px !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
  height: 51px;
  background-color: white;
}

body {
  font-family: 'Open Sans' !important;
  color: #666666;
  font-size: 13px;
}

input {
  border-radius: 0% !important;
}

select {
  border-radius: 0% !important;
}

ul .pager li .pages {
  border-radius: 0% !important;
}

.sqr {
  border-radius: 0% !important;
}
.card {
   border-radius: 0% !important;
}
.card .card-header {
  border-radius: 0% !important;
}

.card-body-spacer {
  margin-top: -15px;
}

.card-title {
  font-family: 'Montserrat';
  font-size: 13px;
}

.li-button {
  width: 120px;
}

.li-body {
  font-family: 'Open Sans' !important;
  color: #666666 !important;
  font-size: 13px !important;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: 'Open Sans' !important;
  color: #666666 !important;
  font-size: 13px !important;
}
::-moz-placeholder { /* Firefox 19+ */
  font-family: 'Open Sans' !important;
  color: #666666 !important;
  font-size: 13px !important;
}
:-ms-input-placeholder { /* IE 10+ */
  font-family: 'Open Sans' !important;
  color: #666666 !important;
  font-size: 13px !important;
}
:-moz-placeholder { /* Firefox 18- */
  font-family: 'Open Sans' !important;
  color: #666666 !important;
  font-size: 13px !important;
}

.footer-text {
  font-family: 'Open Sans' !important;
}